import { Link } from "gatsby"
import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <h1>404</h1>
    <p>
      Nyt kävi hassusti, palaa etusivulle <Link to="/">tästä!</Link>
    </p>
  </Layout>
)

export default NotFoundPage
